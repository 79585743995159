import React from 'react'
import ServiceData from '../../data/Software/whyus.json';
import Stserviceitems from './Stservicesitems'
const WhyUs = () => {
  return (
    <section className="agency_service_area bg_color">
    <div className="container custom_container">
    <div className='main_title mb-60 text-center'>
                {/* <div className='hosting_title text-center '> */}
            <h2 className="our-approach-title">
            <span>Why</span>
           {/* <br /> */}
           Us?
           
          </h2>
                </div>
        {/* <h2 className="f_size_20 f_600 t_color3 l_height40  mb_90 wow fadeInUp why-us-title " data-wow-delay="0.2s">Why <span>Us?</span> </h2> */}
        {/* <h2 className="our-approach-title text-center mb-50">
            <span>Why</span>Us?</h2> */}
        <div className="row mb_30">
            {
                ServiceData.map( items => {
                    return(
                            <div className="col-lg-3 col-sm-6">
                                <Stserviceitems iShap={items.shape}  Sicon={items.icon} text={items.text} description={items.description} btn={items.btn} bicon={items.bicon}/>
                            </div>
                    )
                })
            }
        </div>
    </div>
</section>
  )
}

export default WhyUs