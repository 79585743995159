import React from 'react'
import ServiceWeProvide from '../../comp/ServiceWeProvide/ServiceWeProvide'
import Mission from '../Mission/Mission'

const AboutHeader2 = () => {
  return (
    <main className="portfolio-page style-1">
    <section className={`portfolio-projects style-1 `}>
        {/* <div className="container page-wrapper-a">
               <div className="section-head text-center style-5 ">
                  <h2 className="mb-20">Experts in <br /> <span>Advanced Technologies</span></h2>
                  <p className='wakanda-expert-p'>WAKANDA helps businesses across the globe to develop and deploy innovative solutions that drive their success with our talented team.</p>
                </div>
        </div> */}
        <Mission/>
    </section>
    </main>
  )
}

export default AboutHeader2