import React, { useEffect } from "react";
import "./PortfolioPage.css";
import projects from "../../data/Portfolio/projects.json";
import Download from "../../components/Download/Download";
import NavbarTwo from "../../components/NavbarTwo/NavbarTwo";
import ShowcaseTop from "../../components/ShowcaseTop/ShowcaseTop";
import ContactForm from "../../components/ContactForm/ContactForm";

const PortfolioPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("updated seckjdck");
  }, []);
  const navBlack = true;
  const projectsData = projects;
  const style = "5";
  useEffect(() => {
    setTimeout(() => {
      // if (!mixitup) return;
      // mixitup('.portfolio-projects')
    }, 0);
  }, []);
  const isPortfolioPage = true;
  return (
    <>
      {/* <NavbarTwo navBlack = {navBlack}/> */}
      <ShowcaseTop
        breadcrumbClass="breadcrumb_area"
        imgName="breadcrumb/banner_bg.png"
        Ptitle="Portfolio"
        Pdescription="We are a team of tech-savvy professionals who are passionate about delivering top-notch products to businesses. Our software product lineup includes a variety of projects that employ the latest and most advanced technologies."
      />
      <main className="portfolio-page style-1">
        <section
          className={`portfolio-projects section-padding pt-50 style-1 ${
            style === "5" ? "" : "bg-white"
          }`}
        >
          <div className="container">
            {/* <div className={`section-head page-wrapper text-center style-${style} ${style === '5' ? 'mb-60':'mb-40'}`}>
          <h2 className="mb-20"> Our <span>Projects</span> </h2>
          <p>'We have an experienced team of production and inspection personnel to ensure quality.</p>
        </div> */}
            {/* <div className="controls">
          {
            projectsData.filters.map((filter, i) => (<button key={i} type="button" className="control" data-filter={filter.filter}>{ filter.title }</button>))
          }
        </div> */}
            <section className="portfolio style-1">
              <div className="content">
                <div className="row mix-container">
                  {projectsData.projects.map((project, i) => (
                    <div className={`col-lg-4 mix ${project.filter}`} key={i}>
                      <div
                        className={`portfolio-card ${
                          i !== projects.projects.length - 1 ? "mb-50" : ""
                        }`}
                      >
                        <div className="img">
                          <img src={project.image} alt="" />
                        </div>
                        <div className="info">
                          <h5>
                            <a>{project.title}</a>
                          </h5>
                          <h6 className="tags">
                            <a style={{ fontSize: "1rem" }}>{project.sector}</a>
                          </h6>
                          <small className="d-block color-main text-uppercase">
                            {project.type}
                          </small>
                          <div className="text">{project.details}</div>
                          <div className="tags">
                            {project.tags.map((tag, i) => (
                              <a key={i} href="#" className="me-1">
                                {tag}
                              </a>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="text-center"></div>
            </section>
          </div>
        </section>
      </main>
      <ContactForm isPortfolioPage={isPortfolioPage} />
      <Download />
    </>
  );
};

export default PortfolioPage;
