import './App.css';
import {Routes , Route , Link} from 'react-router-dom'
import Portfolio from './components/Portfolio/Portfolio';
import PortfolioPage from './pages/PortfolioPage/PortfolioPage';
import ServicesPage from './pages/ServicesPage/ServicesPage';
import ContactPage from './pages/ContactPage/ContactPage';
import AboutPage from './pages/AboutPage/AboutPage';
import Footer from './components/Footer/Footer';
import NavbarTwo from './components/NavbarTwo/NavbarTwo';
import HomePage from './pages/HomePage/HomePage';
import CareerPage from './pages/CareerPage/CareerPage';
import CustomNav from './components/CustomNav/CustomNav'

function App() {
  return (
    <>
    <CustomNav/>
    {/* <NavbarTwo/> */}
      <Routes>
        <Route path="/" element={<HomePage/>}/>
        <Route path="/about" element={<AboutPage/>}/>
        <Route path="/services" element={<ServicesPage/>}/>
        <Route path="/projects" element={<PortfolioPage/>}/>
        <Route path="/contact" element={<ContactPage/>}/>
        <Route path="/career" element={<CareerPage/>}/>
      </Routes>
    <Footer/>
    </>
  );
}

export default App;
