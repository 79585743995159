import services from '../../data/ITCreative/services.json';

const ServiceWeProvide = () => {
  return (
    <section className="services style-7 page-color pt-80 mt-100 ">
      <div className="container">
        <div className="section-head text-center style-4 mb-20">
          
          <h2 className="our-approach-title">
            <span>
            Our Approach 
            </span>
           <br />
           <h2>to Reach Your Business Goals</h2>
           
          </h2>
          <p className='our-approach-p'> Driven by Design Thinking and Next-Generation Technologies, we are the right partner of choice for exceeding your digital aspirations. We can help you reach your business goals with the right management and allocation of top talent.</p>
        </div>
        <div className="services-content mt-20 mb-100 pb-40">
          <div className="row">
            {
              services.map((service, index) => (
                <div className="col-lg-3 col-sm-6" key={index}>
                  <div className="service-item-style7">
                    <div className="img">
                      <img src={service.image} alt="" />
                     
                    </div>
                    <div className="info">
                      <h6 className='mb-10'>{ service.title }</h6>
                      <p className="text">{ service.description }</p>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <img src="/assets/img/projects/prog/shap_r.png" alt="" className="shap_r_ap" />
      <img src="/assets/img/projects/prog/shap_l.png" alt="" className="shap_l_ap" />
    </section>
  )
}

export default ServiceWeProvide



// const ServiceWeProvide = () => {
//   return (
//     <section className="services style-7 pt-100">
//       <div className="container">
//         <div className="section-head text-center style-4 mb-50">
//           <div className="top-title mb-10">
//             <img src="/assets/img/line_l.png" alt="" />
//             <h5> Services </h5>
//             <img src="/assets/img/line_r.png" alt="" />
//           </div>
//           <h2 className="">
//             What We <span> Provide </span> <br /> As A Company
//           </h2>
//         </div>
//         <div className="services-content pb-100">
//           <div className="row">
//             {
//               services.map((service, index) => (
//                 <div className="col-lg-3 col-sm-6" key={index}>
//                   <div className="service-item-style7">
//                     <div className="img">
//                       <img src={service.image} alt="" />
//                       <span className="icon">
//                         <i className={service.icon}></i>
//                       </span>
//                     </div>
//                     <div className="info">
//                       <h6>{ service.title }</h6>
//                       <p className="text">{ service.description }</p>
//                       <a href="#"> <i className="fal fa-long-arrow-right"></i> Read More  <i className="fal fa-long-arrow-right"></i> </a>
//                     </div>
//                   </div>
//                 </div>
//               ))
//             }
//           </div>
//         </div>
//       </div>
//     </section>
//   )
// }

// export default ServiceWeProvide