import React, { useEffect } from "react";
import "./HomePage.css";
import HeaderTwo from "../../components/HeaderTwo/HeaderTwo";
import Services from "../../components/Services/Services";
import WhyChooseUs from "../../components/WhyChooseUs/WhyChooseUs";
import Portfolio from "../../components/Portfolio/Portfolio";
import Testimonials from "../../components/Testimonials/Testimonials";
import Blog from "../../components/Blog/Blog";
import Contact from "../../components/Contact/Contact";
import Header from "../../components/Header/Header";
import NavbarTwo from "../../components/NavbarTwo/NavbarTwo";
import ServiceHome from "../../components/ServiceHome/ServiceHome";
const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("updated seckjdck");
  }, []);
  return (
    <div>
      <HeaderTwo />
      {/* <Header/> */}
      {/* <NavbarTwo/> */}
      <ServiceHome />
      {/* <Services/> */}
      <WhyChooseUs />
      <Portfolio />
      <Testimonials />
      {/* <Blog/> */}
      <Contact />
    </div>
  );
};

export default HomePage;
