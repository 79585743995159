import {Link} from 'react-router-dom'
const HeaderTwo = () => {
  return (
    <header className="style-3 overflow-hidden" data-scroll-index="0">
      {/* <div className="container mb-100">
      <div className="content">
        <div className="row">
          <div className="col-lg-6">
            <div className="info">
              <div className="section-head mb-60">
                <h6 className="color-main text-uppercase">Iteck agency</h6>
                <h2 className="h1">
                  Technology & IT <span className="fw-normal">Solutions</span>
                </h2>
              </div>
              <div className="text p">
                We transform businesses of most major sectors with powerful and adaptable digital solutions that satisfy the needs of today.
              </div>
              <div className="bttns mt-5">
                  <a className="btn btn-dark">
                    <span>our services</span>
                  </a>
              
              </div>
            </div>
          </div>
         
        </div>
      </div>
    </div> */}
      <div className="container mt-100 mb-50">
        <div className="content section-padding">
          <div className="row">
            <div className="col-lg-6">
              <div className="info">
              <h2 className="h1">
              Meet Your <span> New Growth Partner</span>
                </h2>
                <p className="p">Leaders use Booking Technologies outsourcing service to scale operations,
cut costs, and support their teams. <br /></p>
               
              </div>
              <div className="bttns mt-5">
                    <Link to = "/services">
                  <a className="btn btn-dark">
                    <span>our services</span>
                  </a> 
                    </Link>
              
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-img">
        <img src="/assets/img/header/Wakanda Website Icons-22.png" alt="" className="pattern" />
        <img src="/assets/img/header/Wakanda Website Icons-21.png" alt="" className="circle" />
        <img src="/assets/img/header/Wakanda Logo Alternative-45.png" alt="" className="logo_shap" />
      </div>
    </header>
  )
}

export default HeaderTwo