import React, { useEffect } from "react";
import "./ContactPage.css";
import FormCP from "../../components/FormCP/FromCP";
import Map from "../../components/Map/Map";
import Footer from "../../components/Footer/Footer";
import NavbarTwo from "../../components/NavbarTwo/NavbarTwo";
import ShowcaseTop from "../../components/ShowcaseTop/ShowcaseTop";
import ContactForm from "../../components/ContactForm/ContactForm";
const ContactPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("updated seckjdck");
  }, []);
  const navBlack = true;
  return (
    <main className="contact-page style-5">
      {/* <NavbarTwo navBlack = {navBlack}/> */}
      <ShowcaseTop
        breadcrumbClass="breadcrumb_area"
        imgName="breadcrumb/banner_bg.png"
        Ptitle="Contact Us"
        Pdescription=""
      />
      <ContactForm />
      {/* <FormCP style="5" /> */}
      <Map className="mt-200" />
    </main>
  );
};

export default ContactPage;
