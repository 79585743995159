import { useEffect, useRef } from "react";
import navbarScrollEffect from "../../common/navbarScrollEffect";
import { Link, NavLink } from "react-router-dom";
const Navbar = () => {
  const navbarRef = useRef(null);
  const collapseRef = useRef(null);
  const hideBars = () => {
    collapseRef.current.setAttribute("class", "navbar-collapse collapse");
  };
  useEffect(() => {
    navbarScrollEffect(navbarRef.current);
  }, [navbarRef]);

  const handleMouseMove = (event) => {
    const dropDownToggler = event.target.classList.contains("dropdown-toggle")
      ? event.target
      : event.target.querySelector(".dropdown-toggle");
    const dropDownMenu = dropDownToggler?.nextElementSibling;

    dropDownToggler?.classList?.add("show");
    dropDownMenu?.classList?.add("show");
  };

  const handleMouseLeave = (event) => {
    const dropdown = event.target.classList.contains("dropdown")
      ? event.target
      : event.target.closest(".dropdown");
    const dropDownToggler = dropdown.querySelector(".dropdown-toggle");
    const dropDownMenu = dropdown.querySelector(".dropdown-menu");

    dropDownToggler?.classList?.remove("show");
    dropDownMenu?.classList?.remove("show");
  };

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light style-1"
      ref={navbarRef}
    >
      <div className="container ">
        {/* <a className="navbar-brand" to="#"> */}
        <Link to="/" className="navbar-brand" onClick={hideBars}>
          <img
            src="/assets/img/Booking LOGO-01.png"
            className="white-logo-w"
            alt=""
          />
        </Link>
        {/* </a> */}
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse " id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto  mb-2 mb-lg-0 style-9">
            <li className="nav-item dropdown">
              <NavLink
                to="/about"
                className={`nav-link `}
                onClick={hideBars}
                exact
              >
                our company
                {/* ({isActive})=> isActive ? "active" : undefined */}
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to="/services" className="nav-link" onClick={hideBars}>
                services
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to="/projects" className="nav-link" onClick={hideBars}>
                portfolio
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to="/career" className="nav-link" onClick={hideBars}>
                career
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to="/contact" className="nav-link" onClick={hideBars}>
                contact
              </NavLink>
            </li>
          </ul>
          {/* <div className="nav-side">
            <div className="hotline pe-4">
              <div className="icon me-3">
                <i className="bi bi-telephone"></i>
              </div>
              <div className="cont">
                <small className="text-muted m-0">hotline 24/7</small>
                <h6>(+23) 5535 68 68</h6>
              </div>
            </div>
            <div className="qoute-nav ps-4">
              <a to="#" className="search-icon me-3">
                <i className="bi bi-search"></i>
              </a>
              <a to="#" className="cart-icon me-3">
                <i className="bi bi-cart"></i>
                <span className="cart-num ms-1">2</span>
              </a>
              <Link to="/page-contact-5">
                <a className="btn sm-butn butn-gard border-0 text-white">
                  <span>Free Quote</span>
                </a>
              </Link>
            </div>
          </div> */}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
