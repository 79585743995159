import {useRef,useState}from "react";
import emailjs from '@emailjs/browser';
const ContactForm = ({ isPortfolioPage }) => {
  const handleChange = () => {};
  const form = useRef();
  const [isSent , setIsSent] = useState(false)

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_umh86ch', 'template_bfysw0a', form.current, 'KrSjIJ6VJtbUAtWqp')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      }
      );
      setIsSent(true)
      e.target.reset()
      setTimeout(()=>{
        setIsSent(false)
      },3000)
  };
  return (
    <section
      className={`contact_info_area sec_pad bg_color mt-20 ${
        isPortfolioPage ? "page-color" : ""
      } mb-150 `}
    >
      <div className={`container`}>
        {isPortfolioPage ? (
          <div className="main_title mb-60 text-center">
            <h2 className="our-approach-title" style={{ fontSize: "2.6rem" }}>
              <span>Contact</span>
              Us
            </h2>
          </div>
        ) : (
          ""
        )}
        <div className="row gap-5 ">
          <div className="col-lg-4 mb-30 ml-40">
            <div className="contact__info">
              <div className="contact__info-background"></div>
              <h3 className="contact__info-heading">What happens next?</h3>
              <br />
              <div className="contact__info-steps">
                <div className="contact__info-block">
                  <div className="contact__info-step">1</div>
                  <p className="contact__info-text">
                    {" "}
                    Having received and processed your request, we will get back
                    to you shortly to detail your project needs and sign an NDA
                    to ensure the confidentiality of information.
                  </p>
                </div>
                <div className="contact__info-block">
                  <div className="contact__info-step">2</div>
                  <p className="contact__info-text">
                    {" "}
                    After examining requirements, our analysts and developers
                    devise a project proposal with the scope of works, team
                    size, time and cost estimates.
                  </p>
                </div>
                <div className="contact__info-block">
                  <div className="contact__info-step">3</div>
                  <p className="contact__info-text">
                    {" "}
                    We arrange a meeting with you to discuss the offer and come
                    to an agreement.
                  </p>
                </div>
                <div className="contact__info-block">
                  <div className="contact__info-step">4</div>
                  <p className="contact__info-text">
                    {" "}
                    We sign a contract and start working on your project as
                    quickly as possible.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="contact_form col-lg-7 m-20">
            <form className="contact_form_box" method="post" id="contactForm" ref={form} onSubmit={sendEmail}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group text_box">
                    <input
                    required
                      type="text"
                      id="name"
                      name="fullName"
                      placeholder="Name"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group text_box">
                    <input
                    required
                      type="text"
                      name="companyName"
                      id="companyName"
                      placeholder="Company"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group text_box">
                    <input
                    required
                       type="email"
                      id="workEmail"
                      name="workEmail"
                      placeholder="Work Email"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group text_box">
                    <input
                    required
                      type="text"
                      name="phone"
                      id="phone"
                      placeholder="Phone"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group text_box">
                    <select
                    required name="subjectService" id="subjectService" className="select-field">
                      <option value="" disabled selected hidden>
                        Subject
                      </option>
                      <option value="IT staff augmentation">
                        IT staff augmentation
                      </option>
                      <option value="product development">
                        Product development
                      </option>
                      <option value="Support and enhancement">
                        Support and enhancement
                      </option>
                      <option value="Careers">Careers</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group text_box">
                    <select
                    required
                      placeholder="Project Budget"
                      name="projectBudget"
                      id="projectBudget"
                      className="select-field"
                    >
                      <option value="" disabled selected hidden>
                        Project Budget
                      </option>
                      <option value="Up to $20K">Up to $20K</option>
                      <option value="$20K-$100K">$20K-$100K</option>
                      <option value="$100K-$500K">$100K-$500K</option>
                      <option value="More than $500K">More than $500K</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group text_box">
                    <input
                    required
                      type="text"
                      id="subject"
                      name="subject"
                      placeholder="Subject"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group text_box">
                    <textarea
                    required
                      onChange={handleChange}
                      name="message"
                      id="message"
                      cols="30"
                      rows="6"
                      placeholder="Enter Your Message . . ."
                    ></textarea>
                  </div>
                </div>
              </div>
              <button type="submit" className="btn_three ">
                Send Message
              </button>
              <p style={{color:'black'}}>{isSent?'messege succesfully sent':''}</p>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
