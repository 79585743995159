import React from "react";
import ServiceData from "../../data/Software/servicehome.json";
import Stserviceitems from "./Stservicesitems";
const ServiceHome = () => {
  return (
    <section className="agency_service_area bg_color mt-50">
      <div className="container custom_container">
        <div className="row">
          <div className="col offset-lg-1">
            <div className="section-head  text-center mb-60">
              <h6 className="color-main text-uppercase wow fadeInUp">
                our services
              </h6>
              <h2 className="wow fadeInUp ">
                Perfect IT Solutions{" "}
                <span className="fw-normal">For Your Business</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="row mb_30">
          {ServiceData.map((items) => {
            return (
              <div className="col-lg-3 col-sm-6 text-center">
                <Stserviceitems
                  iShap={items.shape}
                  Sicon={items.icon}
                  text={items.text}
                  description={items.description}
                  btn={items.btn}
                  bicon={items.bicon}
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ServiceHome;
