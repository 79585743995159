import services from '../../data/Software/services.json';

const Mission = () => {
  return (
    <section className="services style-3 section-padding pb-70" data-scroll-index="2">
      <div className="container">
        <div className="row mission-row">
          {
            services.map((service, index) => (
              <div className="col-lg-6"key={index}>
              <div className="app_service_item wow text-center fadeInUp" data-wow-delay="0.2s">
                  {/* <i className={`app_icon ${service.icon}`}></i> */}
                  <img className='app-icon mb-20 text-center' src={service.icon} alt="" />
                  <h5 className="f_p f_size_18 f_700 font-weight-bold text-center t_color3 mt_40 mb-30">{service.title}</h5>
                  <p className="f_400 f_size_15 mb-20 text-center">{service.details}</p>
                  {/* <a href=".#" className="learn_btn_two">Learn More <i className="ti-arrow-right"></i></a> */}
              </div>
          </div> 
              // <div className=" mission-card " key={index}>
              //   <div className="service-card style-3">
              //     <div className="icon">
              //       <img src={service.icon} alt="" />
              //     </div>
              //     <div className="info">
              //       <h5 className="title">{ service.title }</h5>
              //       <div className="text">
              //         { service.details }
              //       </div>
              //     </div>
              //   </div>
              // </div>
            ))
          }
        </div>
      </div>
    </section>
  )
}

export default Mission