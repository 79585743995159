import React from 'react'

const ServiceDeox = () => {
  return (
    <section className="hosting_service_area sec_pad">
            <div className="container">
                <div className='main_title mb-60 text-center'>
                {/* <div className='hosting_title text-center '> */}
            <h2 className="our-approach-title">
            <span>Challenges</span>
           {/* <br /> */}
           we help to solve
           
          </h2>
                </div>
                <div className="row">
                        <div className="col-lg-4 col-md-6">
                        <div className="service-box service-warning">
                            <div className="mb-5">
                            <i class="pe-7s-tools service-icon">
                                <img src="/assets/img/icons/serv_icons/Wakanda Website Icons-08.png" alt=""/>
                                </i>/
                            </div>
                            <h5 className="service-title text-dark font-weight-normal pt-1 mb-4">IT expertise gap</h5>
                            <p className="text-muted service-subtitle mb-4" style={{fontSize:"1.125rem"}}>We help our customers get rid of expertise and technological gaps, offering the experienced team of IT professionals to complete software development projects of any complexity.</p>
                            <a  className="read-more-icon"><span className="right-icon">&#8594;</span></a>
                        </div>
                    </div>
                        <div className="col-lg-4 col-md-6">
                        <div className="service-box service-warning">
                            <div className="mb-5">
                            <i class="pe-7s-tools service-icon">
                                {/* <img src={require ("../img/hosting/icon1.png")} alt=""/> */}
                                <img src="/assets/img/icons/serv_icons/New Icons-26.png" alt=""/>

                                </i>
                            </div>
                            <h5 className="service-title text-dark font-weight-normal pt-1 mb-4">Cost reduction</h5>
                            <p className="text-muted service-subtitle mb-4" style={{fontSize:"1.125rem"}}>Booking Technologiesprovides IT outsourcing services and helps clients around the world to optimize software development costs. Our team has all the needed expertise to carry out turnkey projects, quickly complete tasks and bring all the benefits of outsourcing.</p>
                            <a  className="read-more-icon"><span className="right-icon">&#8594;</span></a>
                        </div>
                    </div>
                        <div className="col-lg-4 col-md-6">
                        <div className="service-box service-warning">
                            <div className="mb-5">
                            <i class="pe-7s-tools service-icon">
                                {/* <img src={require ("../img/hosting/icon1.png")} alt=""/> */}
                                <img src="/assets/img/icons/serv_icons/25-25.png" alt=""/>

                                </i>
                            </div>
                            <h5 className="service-title text-dark font-weight-normal pt-1 mb-4">Digital transformation</h5>
                            <p className="text-muted service-subtitle mb-4" style={{fontSize:"1.125rem"}}>Our team can help you plan and implement digital transformation initiatives, covering all possible stages. Booking Technologiesis a reliable partner on the path to digital success and business optimization. </p>
                            <a  className="read-more-icon"><span className="right-icon">&#8594;</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
  )
}

export default ServiceDeox