import React from "react";

const Stservicesitems = ({
  iShap,
  Sicon,
  text,
  description,
  btn,
  bicon,
  nClass,
}) => {
  return (
    <div
      className={`p_service_item agency_service_item pr_70 wow  fadeInUp ${nClass}`}
    >
      <div className="icon text-center m-auto">
        {/* <img src={require ("../../../img/home4/" + iShap)} alt=""/> */}
        {/* <i className={Sicon}></i> */}
        <img src={Sicon} alt="" />
      </div>
      <h5 className="f_600 f_p t_color3 mt-20">{text}</h5>
      <p>{description}</p>
      {/* <p className="mb-0"><a href=".#">{btn}</a><i className={bicon}></i></p> */}
    </div>
  );
};

export default Stservicesitems;
