import React, { useEffect } from "react";
import AboutHeader from "../../components/AboutHeader/AboutHeader";
import ChooseUsAP from "../../components/ChooseUsAP/ChooseUsAP";
import ClientsAP from "../../components/ClientsAP/ClientsAP";
import CommunityAP from "../../components/CommunityAP/CommunityAP";
import PhilosophyAP from "../../components/PhilosophyAP/PhilosophyAP";
import FormCP from "../../components/FormCP/FromCP";

import "./AboutPage.css";
import NavbarTwo from "../../components/NavbarTwo/NavbarTwo";
import AboutHeader2 from "../../components/AboutHeader2/AboutHeader2";
import ShowcaseTop from "../../components/ShowcaseTop/ShowcaseTop";
import Testimonials from "../../components/Testimonials/Testimonials";
import WhyUs from "../../components/WhyUs/WhyUs";
import ServiceDeox from "../../components/NewCard/ServiceDeox";
import ServiceWeProvide from "../../comp/ServiceWeProvide/ServiceWeProvide";
import CustomNav from "../../components/CustomNav/CustomNav";
const AboutPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("updated seckjdck");
  }, []);
  const navBlack = true;
  return (
    <main className="style-5">
      {/* <NavbarTwo navBlack = {navBlack}/> */}
      {/* <AboutHeader /> */}
      <ShowcaseTop
        breadcrumbClass="breadcrumb_area"
        imgName="breadcrumb/banner_bg.png"
        Ptitle="About Us"
        Pdescription="Booking Technologies is a software development company that serves IT and non-IT organizations, helping to achieve a variety of goals and objectives with digital solutions and recent trend technologies."
      />
      <AboutHeader2 />
      <ServiceDeox />
      <ServiceWeProvide />
      <WhyUs />
      <Testimonials />

      {/* <CommunityAP /> */}
      {/* <PhilosophyAP/> */}
      {/* <ChooseUsAP /> */}
      {/* <ClientsAP  /> */}
      {/* <Numbers /> */}
      {/* <Culture /> */}
      {/* <Team /> */}
      {/* <FormCP /> */}
    </main>
  );
};

export default AboutPage;
