import React, { useEffect } from "react";
import NavbarTwo from "../../components/NavbarTwo/NavbarTwo";
import ShowcaseTop from "../../components/ShowcaseTop/ShowcaseTop";
import WhyJoin from "../../components/WhyJoin/WhyJoin";
import "./CareerPage.css";
const CareerPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("updated seckjdck");
  }, []);
  const navBlack = true;
  return (
    <div>
      {/* <NavbarTwo navBlack = {navBlack}/> */}
      <ShowcaseTop
        breadcrumbClass="breadcrumb_area"
        imgName="breadcrumb/banner_bg.png"
        Ptitle="Career Page"
        Pdescription="Why I say old chap that is spiffing off his nut arse pear shaped plastered Jeffrey bodge barney some dodgy.!!"
      />
      <main className="portfolio-page style-1">
        <section
          className={`portfolio-projects section-padding pt-50 style-1 `}
        >
          {/* <div className="container page-wrapper-a">
               <div className="section-head text-center style-5 ">
                  <h2 className="mb-20">This is <span>Career Page</span></h2>
                  <p className='wakanda-expert-p'>Take your career to the next level with Wakanda! Joining our dynamic team is the key to unlocking new opportunities and transforming your future0.</p>
                </div>
        </div> */}
          <WhyJoin />
        </section>
      </main>
    </div>
  );
};

export default CareerPage;
