import { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
// import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import contactInfo from "../../data/Digital/contact.json";

const Contact = () => {
  const notify = () => toast("Message sent succesfully!");

  const [formData, setFormdata] = useState({
    name: "",
    email: "",
    option: "",
    message: "",
  });
  const [isSent , setIsSent] = useState(false)
  const handleFormChange = (e) => {
    setFormdata((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_umh86ch",
        "template_bfysw0a",
        form.current,
        "KrSjIJ6VJtbUAtWqp"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
      setIsSent(true)
      e.target.reset()
      toast.success("Success!");
      notify()
      setTimeout(()=>{
        setIsSent(false)
      },3000)
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
  };
  const handleChange = async (e) => {
    e.preventDefault();
  };

  return (
    <section
      className="contact section-padding bg-gradient style-1"
      data-scroll-index="7"
    >
      <div className="container">
        <div className="section-head mb-60 text-center">
          <h6 className="text-white text-uppercase wow fadeInUp">contact us</h6>
          <h2 className="wow fadeInUp text-white">
            Let’s get <span className="fw-normal">started</span>
          </h2>
        </div>
        <div className="content">
          <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className="contact_info text-white">
                <p className="wow fadeInUp">Phone Number</p>
                <h4 className="wow fadeInUp">{contactInfo.phone}</h4>
                <ul>
                  <li className="wow fadeInUp">
                    <strong>Address : </strong> {contactInfo.address}
                  </li>
                  <li className="wow fadeInUp">
                    <strong>Email : </strong> {contactInfo.email}
                  </li>
                  <li className="wow fadeInUp">
                    <strong>Work Hour : </strong> {contactInfo.workingHours}
                  </li>
                </ul>
                <a href="#" className="wow fadeInUp">
                  get direction
                </a>
              </div>
            </div>
            <div className="contact_form col-lg-6 home_page_contact m-20">
              <form
                className="contact_form_box"
                id="contactForm"
                ref={form}
                onSubmit={sendEmail}
              >
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group text_box">
                      <input
                      required
                        type="text"
                        id="name"
                        name="fullName"
                        placeholder="Name"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group text_box">
                      <input
                      required
                        type="text"
                        name="companyName"
                        id="companyName"
                        placeholder="Company"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group text_box">
                      <input
                      required
                        type="email"
                        id="workEmail"
                        name="workEmail"
                        placeholder="Work Email"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group text_box">
                      <input
                      required
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder="Phone"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group text_box">
                      <select
                       required
                        name="subjectService"
                        id="subjectService"
                        className="select-field"
                      >
                        <option value="" disabled selected hidden>
                          Subject
                        </option>
                        <option value="IT staff augmentation">
                          IT staff augmentation
                        </option>
                        <option value="product development">
                          Product development
                        </option>
                        <option value="Support and enhancement">
                          Support and enhancement
                        </option>
                        <option value="Careers">Careers</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group text_box">
                      <select
                       required
                         placeholder="Project Budget"
                         name="projectBudget"
                         id="projectBudget"
                        className="select-field"
                      >
                        <option value="" disabled selected hidden>
                          Project Budget
                        </option>
                        <option value="Up to $20K">Up to $20K</option>
                        <option value="$20K-$100K">$20K-$100K</option>
                        <option value="$100K-$500K">$100K-$500K</option>
                        <option value="More than $500K">More than $500K</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group text_box">
                      <input
                      required
                         type="text"
                         id="subject"
                         name="subject"
                         placeholder="Subject"
                         onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group text_box">
                      <textarea
                      required
                        onChange={handleChange}
                        name="message"
                        id="message"
                        cols="30"
                        rows="6"
                        placeholder="Enter Your Message . . ."
                      ></textarea>
                    </div>
                  </div>
                </div>
                <button type="submit" className=" black_btn"  >
                  Send Message
                </button>
                <ToastContainer />
                <p style={{color:'white'}}>{isSent?'messege succesfully sent':''}</p>
              </form>
            </div>
            {/* <div className="col-lg-6 offset-lg-1">
              <form
                className="contact_form"
                action="contact.php"
                method="post"
                onSubmit={handleFormSubmit}
              >
                <div className="row gx-3">
                  <div className="col-lg-6">
                    <div className="form-group mb-3 wow fadeInUp">
                      <input
                      required
                        type="text"
                        name="name"
                        className="form-control"
                        placeholder="name *"
                        onChange={handleFormChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group mb-3 wow fadeInUp">
                      <input
                      required
                        type="text"
                        name="email"
                        className="form-control"
                        placeholder="Email Address *"
                        onChange={handleFormChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-3 wow fadeInUp">
                      <select
                       required
                        name="option"
                        className="form-select"
                        aria-label="Default select example"
                        defaultValue="Your inquiry about"
                        onChange={handleFormChange}
                      >
                        <option>Your inquiry about</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-3 wow fadeInUp">
                      <textarea
                        className="form-control"
                        name="message"
                        rows="4"
                        placeholder="Write your inquiry here"
                        onChange={handleFormChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-check mb-4 wow fadeInUp">
                      <input
                      required
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label
                        className="form-check-label text-light small"
                        htmlFor="flexCheckDefault"
                      >
                        By submitting, i’m agreed to the{" "}
                        <a href="#" className="text-decoration-underline">
                          Terms & Conditons
                        </a>
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <input
                    required
                      type="submit"
                      value="Request Now"
                      className="btn btn-dark wow fadeInUp text-light fs-14px"
                    ></input>
                  </div>
                </div>
              </form>
            </div> */}
          </div>
        </div>
      </div>
      <img
        src="/assets/img/contact_globe.svg"
        alt=""
        className="contact_globe"
      />
    </section>
  );
};

export default Contact;
