import { useState } from 'react';
// import testimonials from '../../data/Digital/testimonials.json'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import testimonials from '../../data/Saas/testimonials.json';

import "swiper/css";
import 'swiper/css/autoplay';
import 'swiper/css/pagination';

SwiperCore.use([Pagination, Autoplay]);

const Testimonials = () => {
  const testimonialsData =  testimonials;

  return (
    <section className="testimonials section-padding bg-gray5 style-5" data-scroll-index="5">
      <div className="container">
      <div className="section-head mb-60 text-center">
         <h6 className="color-main text-uppercase wow fadeInUp">testimonials</h6>
         <h2 className="wow fadeInUp">
           The Trust <span className="fw-normal">From Clients</span>
         </h2>
    </div>
      </div>
      <div className="content">
        <div className="testimonial-slider position-relative style-5">
          <Swiper
            className="swiper-container pb-70"
            spaceBetween={0}
            slidesPerView={4}
            speed={1000}
            pagination={{
              el: ".testimonial-slider.style-5 .swiper-pagination",
              clickable: true,
            }}
            navigation={false}
            mousewheel={false}
            keyboard={true}
            autoplay={{
              delay: 4000
            }}
            loop={true}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              480: {
                slidesPerView: 1,
              },
              787: {
                slidesPerView: 2,
              },
              991: {
                slidesPerView: 3,
              },
              1200: {
                slidesPerView: 3,
              }
            }}
          >
          {
              testimonialsData.map((testimonial, i) => (
                <SwiperSlide key={i}>
                  <a href="#" className="testi-card style-5">
                    <div className="stars">
                      { Array(testimonial.stars).fill().map((_, t) => (<i className="fas fa-star" key={t}></i>)) }
                    </div>
                    <div className="text">
                      { testimonial.comment }
                    </div>
                    <div className="user mt-40 text-center">
                      {/* <div className="icon-80 rounded-circle img-cover overflow-hidden m-auto">
                        <img src={testimonial.author.image} alt="" />
                      </div> */}
                      <h6>{ testimonial.author.name }</h6>
                      <h6 className='test-status'>{testimonial.author.status}</h6>
                      <small>{ testimonial.author.position }</small>
                    </div>
                  </a>
                </SwiperSlide>
              ))
            }
          </Swiper>
          <div className="swiper-pagination"></div>
        </div>
      </div>
    </section>
    // <section className="testimonials section-padding style-1" data-scroll-index="5">
    //   <div className="container">
    //     <div className="section-head mb-60 text-center">
    //       <h6 className="color-main text-uppercase wow fadeInUp">testimonials</h6>
    //       <h2 className="wow fadeInUp">
    //         The Trust <span className="fw-normal">From Clients</span>
    //       </h2>
    //     </div>
    //     <div className="content">
    //     <div className="testimonial-slider position-relative style-5">
    //       <Swiper
    //         className="swiper-container pb-70"
    //         spaceBetween={0}
    //         slidesPerView={4}
    //         speed={1000}
    //         pagination={{
    //           el: ".testimonial-slider.style-5 .swiper-pagination",
    //           clickable: true,
    //         }}
    //         navigation={false}
    //         mousewheel={false}
    //         keyboard={true}
    //         autoplay={{
    //           delay: 4000
    //         }}
    //         loop={true}
    //         breakpoints={{
    //           0: {
    //             slidesPerView: 1,
    //           },
    //           480: {
    //             slidesPerView: 1,
    //           },
    //           787: {
    //             slidesPerView: 2,
    //           },
    //           991: {
    //             slidesPerView: 3,
    //           },
    //           1200: {
    //             slidesPerView: 4,
    //           }
    //         }}
    //       >
    //         {
    //           testimonialsData.map((testimonial, i) => (
    //             <SwiperSlide key={i}>
    //               <a href="#" className="testi-card style-5">
    //                 <div className="stars">
    //                   { Array(testimonial.stars).fill().map((_, t) => (<i className="fas fa-star" key={t}></i>)) }
    //                 </div>
    //                 <div className="text">
    //                   { testimonial.comment }
    //                 </div>
    //                 <div className="user mt-40 text-center">
    //                   <div className="icon-80 rounded-circle img-cover overflow-hidden m-auto">
    //                     <img src={testimonial.author.image} alt="" />
    //                   </div>
    //                   <h6>{ testimonial.author.name }</h6>
    //                   <small>{ testimonial.author.position }</small>
    //                 </div>
    //               </a>
    //             </SwiperSlide>
    //           ))
    //         }
    //       </Swiper>
    //       <div className="swiper-pagination"></div>
    //     </div>
    //   </div>
        /* <div className="content">
          <div className="row">
            <div className="col-lg-5">
              <div className="vid_img mb-2 mb-md-0 wow zoomIn slow">
                <img src="/assets/img/testimonials/testi.jpeg" alt="" />
                <a href="https://youtu.be/pGbIOC83-So?t=21" className="play_icon" >
                  <i className="bi bi-play"></i>
                </a>
                <div className="img_info wow fadeInUp">
                  <h4><a href="#">Casper Defloy</a></h4>
                  <small><a href="#">Tech Leader at Esty Inc</a></small>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="info wow fadeInUp">
                {
                  testimonials.map((testimonial, index) => (
                    <div className={`client_card ${index !== testimonials.length - 1 ? 'mb-2':''}`} data-wow-delay={index * 0.2 + "s"} key={index}>
                      <div className="user_img">
                        <img src={testimonial.userImg} alt="" />
                      </div>
                      <div className="inf_content">
                        <div className="rate_stars">
                          {
                            Array(testimonial.stars).fill().map((_,i) => (<i key={i} className="bi bi-star-fill"></i>))
                          }
                        </div>
                        <h6>
                          “{ testimonial.comment }”
                        </h6>
                        <p>{ testimonial.username }  <span className="text-muted">/  { testimonial.position }</span></p>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div> */
    //   </div>
    // </section>
  )
}

export default Testimonials