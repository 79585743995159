import React from "react";
import Fade from "react-reveal/Fade";
import "./ShowcaseTop.css";
const ShowcaseTop = ({ Ptitle, Pdescription, breadcrumbClass }) => {
  return (
    <section className={`${breadcrumbClass}`}>
      <div className="container">
        <div className="breadcrumb_content text-center">
          <Fade bottom>
            <h1 className="f_p f_700 f_size_50 w_color l_height50 mb_20">
              {Ptitle}
            </h1>
          </Fade>
          <p className="f_400 w_color f_size_16 l_height26">{Pdescription}</p>
        </div>
      </div>
    </section>
  );
};

export default ShowcaseTop;
